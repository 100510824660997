<template>
  <b-container class="bg-white pb-4">
    <b-row v-if="!permission" class="py-4">
      <b-col>
        You do not have permissions for this page
      </b-col>
    </b-row>
    <b-row v-if="permission && !loaded" class="py-4">
      <b-col>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="!saving && loaded && permission" class="mb-4">
      <b-col>
        <b-overlay :show="saving">
          <div>
            <h1 class="mb-4">
              {{standard.name}}
              <span v-if="!edit"> - New</span>
              <span v-if="edit">edit:
                <span v-if="!standard.routeName">{{form[name]}}</span>
                <span v-if="standard.routeName"><router-link :to="{ name: standard.routeName, params: {id: $route.params.id }}">{{form[name]}}</router-link></span>
              </span>
            </h1>
            <div v-for="field in standard.fields" :key="'field-' + field.name" class="mb-4">
              <div v-if="field.type==='editor'">
                <div>{{field.label}}</div>
                <div class="editor">
                  <div :editor="editors[field.name]">
                    <div class="menubar">
                      <button v-if="field.editor.buttons.bold" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('bold') }" @click="editors[field.name].chain().toggleBold().focus().run()">
                        <div class="icon">
                          <b-icon-type-bold title="Bold"></b-icon-type-bold>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.italic" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('italic') }" @click="editors[field.name].chain().toggleItalic().focus().run()">
                        <div class="icon">
                          <b-icon-type-italic title="Italic"></b-icon-type-italic>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.underline" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('underline') }" @click="editors[field.name].chain().toggleUnderline().focus().run()">
                        <div class="icon">
                          <b-icon-type-underline title="Underline"></b-icon-type-underline>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.h2" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('heading2') }" @click="editors[field.name].chain().focus().toggleHeading({ level: 2 }).run()">
                        <div class="icon">
                          <b-icon-type-h-2 title="Medium Heading"></b-icon-type-h-2>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.h3" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('heading3') }" @click="editors[field.name].chain().focus().toggleHeading({ level: 3 }).run()">
                        <div class="icon">
                          <b-icon-type-h-3 title="Small Heading"></b-icon-type-h-3>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.ul" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('bullet_list') }" @click="editors[field.name].chain().toggleBulletList().focus().run()">
                        <div class="icon">
                          <b-icon-list-ul title="Bullet List"></b-icon-list-ul>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.ol" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('ordered_list') }" @click="editors[field.name].chain().toggleOrderedList().focus().run()">
                        <div class="icon">
                          <b-icon-list-ol title="Ordered List"></b-icon-list-ol>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.quote" class="menubar__button" :class="{ 'is-active': editors[field.name].isActive('blockquote') }" @click="editors[field.name].chain().toggleBlockquote().focus().run()">
                        <div class="icon">
                          <b-icon-chat-left-quote title="Quote"></b-icon-chat-left-quote>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.undo" class="menubar__button" @click="editors[field.name].chain().undo().focus().run()">
                        <div class="icon">
                          <b-icon-arrow-counterclockwise title="Undo"></b-icon-arrow-counterclockwise>
                        </div>
                      </button>
                      <button v-if="field.editor.buttons.redo" class="menubar__button" @click="editors[field.name].chain().redo().focus().run()">
                        <div class="icon">
                          <b-icon-arrow-clockwise title="Redo"></b-icon-arrow-clockwise>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="editor">
                  <editor-content class="editor__content" :editor="editors[field.name]" />
                </div>
              </div>
              <div v-if="field.type==='input'">
                <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
                  <b-form-input :id="'input-description-' + field.name" v-model="form[field.name]" :placeholder="field.placeholder" :rows="field.rows"></b-form-input>
                </b-form-group>
              </div>
              <div v-if="field.type==='date'">
                <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
                  <b-form-input :id="'input-description-' + field.name" v-model="form[field.name]" type="date"></b-form-input>
                </b-form-group>
              </div>
              <div v-if="field.type==='select'">
                <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
                  <b-form-select :id="'input-description-' + field.name" v-model="form[field.name]" :options="field.options"></b-form-select>
                </b-form-group>
              </div>
              <div v-if="field.type==='selectOneFromDatabase' || field.type==='selectOneFromDatabaseOwn'">
                <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
                  <b-form-select :id="'input-description-' + field.name" v-model="form[field.name]" :options="fields[field.name]['options']"></b-form-select>
                </b-form-group>
              </div>
              <div v-if="field.type==='textarea'">
                <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
                  <b-form-textarea :id="'input-description-' + field.name" v-model="form[field.name]" :placeholder="field.placeholder" :rows="field.rows"></b-form-textarea>
                </b-form-group>
              </div>
              <div v-if="field.type==='checkbox'">
                <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
                  <b-form-checkbox :id="'input-description-' + field.name" v-model="form[field.name]" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-form-group>
              </div>
              <div v-if="field.validation" class="mb-4"><small><b-icon-exclamation-circle-fill variant="danger" v-if="!valid.fields[field.name]"></b-icon-exclamation-circle-fill> {{field.validation.text}}</small></div>
            </div>
            <div v-for="field in standard.fields" :key="'field-2-' + field.name" class="mb-4">
              <div v-if="edit && field.type==='selectList'" class="mb-5">
                <select-list
                  :editable="false"
                  :editAlwaysOn="true"
                  :labelDelField="field.config.labelDelField"
                  :labelLinkingField="field.config.labelLinkingField"
                  :labelModel="field.config.labelModel"
                  :linkingModel="field.config.linkingModel"
                  :name="field.config.name"
                  :nameProperty="field.config.nameProperty ? field.config.nameProperty : 'name'"
                  :parentId="parseInt($route.params.id)"
                  :parentLinkingField="field.config.parentLinkingField"
                  :relation="field.config.relation"
                ></select-list>
              </div>
              <div v-if="edit && field.type==='selectListSlim'" class="mb-5">
                <select-list-slim
                  :editable="false"
                  :editAlwaysOn="true"
                  :labelDelField="field.config.labelDelField"
                  :labelLinkingField="field.config.labelLinkingField"
                  :labelModel="field.config.labelModel"
                  :linkingModel="field.config.linkingModel"
                  :name="field.config.name"
                  :nameProperty="field.config.nameProperty ? field.config.nameProperty : 'name'"
                  :parentId="parseInt($route.params.id)"
                  :parentLinkingField="field.config.parentLinkingField"
                  :relation="field.config.relation"
                ></select-list-slim>
              </div>
              <div v-if="edit && field.type==='idLookup'" class="mb-5">
                <id-lookup
                  :editable="false"
                  :editAlwaysOn="true"
                  :labelDelField="field.config.labelDelField"
                  :labelLinkingField="field.config.labelLinkingField"
                  :labelModel="field.config.labelModel"
                  :linkingModel="field.config.linkingModel"
                  :name="field.config.name"
                  :nameProperty="field.config.nameProperty ? field.config.nameProperty : 'name'"
                  :parentId="parseInt($route.params.id)"
                  :parentLinkingField="field.config.parentLinkingField"
                  :relation="field.config.relation"
                ></id-lookup>
              </div>
              <div v-if="edit && field.type==='links'" class="mb-5">
                <links-component
                  :model="field.config.model"
                  :property="field.config.property"
                  :parentId="parseInt($route.params.id)"
                ></links-component>
              </div>
              <div v-if="edit && field.type==='relationships'" class="mb-5">
                <relationships
                  :id="parseInt($route.params.id)"
                  :graph="field.config.graph"
                ></relationships>
              </div>
            </div>
            <div>
              <b-button class="mr-4" variant="outline-primary" v-if="valid.form" @click="save">Save</b-button>
              <b-button variant="outline-primary" @click="cancel">Cancel</b-button>
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import async from 'async'
import standards from '@/libs/standards'

import IdLookup from '@/components/sql/IdLookup.vue'
import LinksComponent from '@/components/sql/Links.vue'
import Relationships from '@/components/sql/Relationships.vue'
import SelectList from '@/components/sql/SelectList.vue'
import SelectListSlim from '@/components/sql/SelectListSlim.vue'

import moment from 'moment'
import { BIconArrowClockwise, BIconArrowCounterclockwise, BIconChatLeftQuote, BIconExclamationCircleFill, BIconListOl, BIconListUl, BIconTypeBold, BIconTypeH2, BIconTypeH3, BIconTypeItalic, BIconTypeUnderline } from 'bootstrap-vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Blockquote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import History from '@tiptap/extension-history'

export default {
  name: 'StandardFormNew',
  components: { EditorContent, BIconTypeBold, BIconTypeItalic, BIconArrowClockwise, BIconArrowCounterclockwise, BIconChatLeftQuote, BIconExclamationCircleFill, BIconListOl, BIconListUl, BIconTypeH2, BIconTypeH3, BIconTypeUnderline, IdLookup, LinksComponent, Relationships, SelectList, SelectListSlim },
  computed: {
    valid: function () {
      const response = {
        fields: {},
        form: false
      }
      const lines = []
      const form = this.form
      let line = true
      this.standard.fields.forEach(field => {
        if ('validation' in field) {
          line = field.validation.method(form[field.name])
        } else {
          line = true
        }
        lines.push(line)
        response.fields[field.name] = line
      })
      response.form = lines.every(x => x === true)
      return response
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    if (this.$route.params.id) {
      this.edit = true
    }
    this.$stat.log({ page: 'form', action: 'open form' })
    this.standard = standards.find(x => x.id === this.$route.params.model)
    if (this.standard.nameProperty) {
      this.name = this.standard.nameProperty
    } else {
      this.name = this.standard.fields[0].name
    }
    this.permission = ac.can(this.user.acgroups).createAny(this.standard.model).granted
    const form = {}
    try {
      await async.each(this.standard.fields, async.asyncify(async (field) => {
        if (field.type === 'selectOneFromDatabase') {
          const options = await this.$Amplify.API.get('cosmos', `/standard/${field.optionsModel}`)
          Object.defineProperty(this.fields, field.name, {
            value: {
              options: options.map(y => {
                return { value: y.id, text: y.name }
              })
            }
          })
        }
        if (field.type === 'selectOneFromDatabaseOwn') {
          const options = await this.$Amplify.API.get('cosmos', `/standard/${field.optionsModel}/own/${this.user.username}`)
          Object.defineProperty(this.fields, field.name, {
            value: {
              options: options.map(y => {
                return { value: y.id, text: y.name }
              })
            }
          })
        }
      }))
    } catch (e) {
      console.log(e)
    }
    this.standard.fields.forEach(x => {
      if (x.type === 'selectList' && this.edit) {
        return
      }
      if (x.type === 'select') {
        form[x.name] = x.selected
      }
      if (x.type === 'selectOneFromDatabase') {
        if (x.selected) {
          form[x.name] = x.selected
        } else {
          form[x.name] = this.fields[x.name].options[0].value
        }
      }
      if (x.type === 'selectOneFromDatabaseOwn') {
        form[x.name] = this.fields[x.name].options[0].value
      }
    })
    this.form = form
    if (this.edit) {
      const record = await this.$Amplify.API.get('cosmos', `/standard/${this.standard.model}/item/${this.$route.params.id}`)
      this.standard.fields.forEach(x => {
        if (x.type === 'date' && record[x.name] !== null) {
          this.form[x.name] = moment(record[x.name]).format('YYYY-MM-DD')
        } else {
          this.form[x.name] = record[x.name]
        }
      })
    }
    const editors = {}
    this.standard.fields.forEach(x => {
      if (x.type === 'editor') {
        editors[x.name] = new Editor({
          extensions: [
            Document,
            Paragraph,
            Text,
            Blockquote,
            BulletList,
            HardBreak,
            ListItem,
            OrderedList,
            TaskItem,
            TaskList,
            Link,
            Bold,
            Italic,
            Underline,
            History,
            Heading.configure({
              levels: [2, 3]
            })
          ],
          content: this.form[x.name],
          onUpdate: () => {
            const html = this.editors[x.name].getHTML()
            this.form[x.name] = html
          }
        })
      }
    })
    this.editors = editors
    this.loaded = true
    console.log(this.form)
  },
  data () {
    return {
      edit: false,
      editors: null,
      fields: {},
      form: null,
      loaded: false,
      name: '',
      moment: moment,
      permission: false,
      saving: false,
      standard: null
    }
  },
  methods: {
    cancel: function () {
      this.$router.go(-1)
    },
    save: async function () {
      this.saving = true
      this.standard.fields.forEach(x => {
        if (x.type === 'date' && this.form[x.name] === "") {
          this.form[x.name] = null
        }
      })
      try {
        let id
        const params = {
          body: this.form
        }
        if (this.edit) {
          await this.$Amplify.API.put('cosmos', `/standard/${this.standard.model}/${this.$route.params.id}`, params)
          id = this.$route.params.id
        } else {
          const newItem = await this.$Amplify.API.put('cosmos', `/standard/${this.standard.model}`, params)
          id = newItem.id
        }
        if (this.standard.routeName) {
          this.$router.push({ name: this.standard.routeName, params: { id: id } })
        }
      } catch (e) {
        this.$logger.warn('saving error', e)
      }
      this.saving = false
    }
  }
}
</script>

<style lang="scss">

$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.menubar {

  border: 0px;
  border-style: solid;
  font-size: 20px;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.editor__content {
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  background-color: white;
  height: 20vh;
  overflow-y: scroll;
  padding: 10px;
}

.ProseMirror {
  border: 0px;
  border-color: white;
  outline-width: 0px;
  outline-style: none;
}

blockquote {
  border-left: 3px solid rgba($color-black, 0.1);
  color: rgba($color-black, 0.8);
  padding-left: 0.8rem;
  font-style: italic;

  p {
    margin: 0;
  }
}
</style>
