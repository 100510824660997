import countries from './countries'

const countriesOptions = countries.map(x => {
  return { value: x.country, text: x.country }
})

const countriesCodesOptions = countries.map(x => {
  return { value: x.alpha2, text: x.country }
})

countriesOptions.unshift({ value: null, text: 'select country' })
const deleteOptions = [{ value: false, text: 'active' }, { value: true, text: 'deleted' }]
const booleanOptions = [{ value: false, text: 'false' }, { value: true, text: 'true' }]

export default [
  {
    id: 'assetclass',
    model: 'assetclass',
    name: 'Assetclass',
    routeName: 'Assetclasses',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'assurance',
    model: 'assurance',
    name: 'Assurance required',
    routeName: 'Assurance',
    fields: [
      {
        name: 'name',
        label: 'assurance required',
        placeholder: 'yes / no / depends',
        type: 'input'
      }
    ]
  },
  {
    id: 'case',
    model: 'case',
    name: 'Case',
    routeName: 'Case',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Details',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'tailoring',
        label: 'Risk tailoring',
        placeholder: 'Considerations for risk tailoring',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'products',
        label: 'Products free text',
        placeholder: 'Further details on products involved',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'regulatory',
        label: 'Regulatory sanctions',
        placeholder: 'Details of regulatory sanctions',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'criminal',
        label: 'Criminal sanctions',
        placeholder: 'Details of criminal sanctions',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'loss',
        label: 'Loss in USD',
        placeholder: '1,000,000',
        type: 'input',
        validation: {
          method: function (value) {
            return Number.isInteger(Number(value))
          },
          text: 'Needs to be a whole dollar amount (integer)'
        }
      },
      {
        name: 'publication',
        label: 'News',
        type: 'idLookup',
        config: {
          labelLinkingField: 'publication_id',
          labelModel: 'publication',
          linkingModel: 'casepublication',
          name: 'News',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'coverage_id',
        label: 'Coverage',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'coverage'
      },
      {
        name: 'start',
        label: 'Incident start',
        type: 'date'
      },
      {
        name: 'end',
        label: 'Incident end',
        type: 'date'
      },
      {
        name: 'jurisdictions',
        label: 'Jurisdictions',
        type: 'selectList',
        config: {
          labelLinkingField: 'jurisdiction_id',
          labelModel: 'jurisdiction',
          linkingModel: 'casejurisdiction',
          name: 'Jurisdictions',
          nameProperty: 'country',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'assetclasses',
        label: 'Asset classes',
        type: 'selectList',
        config: {
          labelLinkingField: 'assetclass_id',
          labelModel: 'assetclass',
          linkingModel: 'assetclasscase',
          name: 'Asset classes',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'producttypes',
        label: 'Product types',
        type: 'selectList',
        config: {
          labelLinkingField: 'producttype_id',
          labelModel: 'producttype',
          linkingModel: 'caseproducttype',
          name: 'Product types',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'controlrisks',
        label: 'Control risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'controlrisk_id',
          labelModel: 'controlrisk',
          linkingModel: 'casecontrolrisk',
          name: 'Control risks',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'risks',
        label: 'MAR risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'risk_id',
          labelModel: 'risk',
          linkingModel: 'caserisk',
          name: 'MAR risks',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      },
      {
        name: 'persons',
        label: 'Persons',
        type: 'selectList',
        config: {
          labelLinkingField: 'person_id',
          labelModel: 'person',
          linkingModel: 'caseperson',
          name: 'Persons',
          parentLinkingField: 'case_id',
          relation: 'cases'
        }
      }
    ]
  },
  {
    id: 'control',
    model: 'control',
    name: 'Control',
    nameProperty: 'name',
    routeName: 'Control',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'objective',
        label: 'Objective',
        placeholder: 'Objective',
        type: 'textarea',
        rows: 6
      },
      {
        name: 'description',
        label: 'Control description',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'status_id',
        label: 'Status',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'controlstatus'
      },
      {
        name: 'theme',
        label: 'Themes',
        type: 'selectList',
        config: {
          labelLinkingField: 'controltheme_id',
          labelModel: 'controltheme',
          linkingModel: 'controlcontroltheme',
          name: 'Themes',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'risk',
        label: 'Risks',
        type: 'selectList',
        config: {
          labelLinkingField: 'controlrisk_id',
          labelModel: 'controlrisk',
          linkingModel: 'controlcontrolrisk',
          name: 'Risks',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'area',
        label: 'Areas',
        type: 'selectList',
        config: {
          labelLinkingField: 'area_id',
          labelModel: 'area',
          linkingModel: 'areacontrol',
          name: 'Areas',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'project',
        label: 'Projects',
        type: 'selectList',
        config: {
          labelLinkingField: 'faqproject_id',
          labelModel: 'faqproject',
          linkingModel: 'controlfaqproject',
          name: 'Projects',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      },
      {
        name: 'obligation',
        label: 'Obligation',
        type: 'idLookup',
        config: {
          labelLinkingField: 'obligation_id',
          labelModel: 'obligation',
          linkingModel: 'controlobligation',
          name: 'Obligations',
          parentLinkingField: 'control_id',
          relation: 'controls'
        }
      }
    ]
  },
  {
    id: 'controlrisk',
    model: 'controlrisk',
    name: 'Control risk',
    nameProperty: 'name',
    routeName: 'ControlRisks',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Description',
        type: 'textarea',
        rows: 6
      }
    ]
  },
  {
    id: 'controlstatus',
    model: 'controlstatus',
    name: 'Control status',
    routeName: 'ControlStatuses',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'controltheme',
    model: 'controltheme',
    name: 'Control theme',
    nameProperty: 'name',
    routeName: 'ControlThemes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Description',
        type: 'textarea',
        rows: 6
      }
    ]
  },
  {
    id: 'coverage',
    model: 'coverage',
    name: 'Coverage',
    routeName: 'Coverage',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'faq',
    model: 'faq',
    name: 'Frequently Asked Question',
    nameProperty: 'question',
    routeName: 'Faq',
    fields: [
      {
        name: 'fact',
        label: 'Fact pattern',
        placeholder: 'Short context',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'question',
        label: 'Question',
        placeholder: 'Question',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters.'
        }
      },
      {
        name: 'answer',
        label: 'Short answer',
        placeholder: 'Short answer',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 1
            } else {
              return false
            }
          },
          text: 'Needs more than 1 character.'
        }
      },
      {
        name: 'long',
        label: 'Long answer',
        placeholder: 'Long answer',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'regulations',
        label: 'Regulatory references',
        placeholder: 'Regulatory references',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'insights',
        label: 'Insights references',
        placeholder: 'Insights references',
        type: 'textarea',
        rows: 3
      },
      {
        name: 'opportunity',
        label: 'Opportunity',
        placeholder: 'Opportunity number',
        type: 'input'
      },
      {
        name: 'faqproject_id',
        label: 'Project',
        type: 'selectOneFromDatabaseOwn',
        optionsModel: 'faqproject'
      }
    ]
  },
  {
    id: 'faqproject',
    model: 'faqproject',
    name: 'FAQ Project',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'ugroup',
        label: 'Usergroup',
        type: 'selectList',
        config: {
          labelLinkingField: 'ugroup_id',
          labelModel: 'ugroup',
          linkingModel: 'faqprojectugroup',
          name: 'Usergroups',
          parentLinkingField: 'faqproject_id',
          relation: 'faqprojects'
        }
      }
    ]
  },
  {
    id: 'impactexpert',
    model: 'impactexpert',
    name: 'KPMG expert',
    routeName: 'ImpactExpert',
    fields: [
      {
        name: 'forename',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'surname',
        label: 'Surname',
        placeholder: 'Surname',
        type: 'input'
      },
      {
        name: 'title',
        label: 'Grade',
        placeholder: 'Grade (e.g. Partner)',
        type: 'input'
      },
      {
        name: 'role',
        label: 'Role',
        placeholder: 'Role (e.g. Head of...)',
        type: 'input'
      },
      {
        name: 'email',
        label: 'Email',
        placeholder: 'exmple@email.com',
        type: 'input'
      },
      {
        name: 'phone',
        label: 'Phone',
        placeholder: 'provide country code + 00 000 000 000',
        type: 'input'
      },
      {
        name: 'country',
        label: 'Country',
        selected: null,
        type: 'select',
        options: countriesOptions
      }
      /*
      {
        name: 'initiative',
        label: 'Initiatives',
        type: 'selectList',
        config: {
          labelDelField: 'impactinitiatives.del',
          labelLinkingField: 'impactinitiative_id',
          labelModel: 'impactinitative',
          linkingModel: 'impactexpertimpactinitiative',
          name: 'Initiatives',
          parentLinkingField: 'impactexpert_id',
          relation: 'impactexperts'
        }
      }
      */
    ]
  },
  {
    id: 'impactinitiative',
    model: 'impactinitiative',
    name: 'Initiative',
    nameProperty: 'name',
    routeName: 'ImpactInitiative',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'headline',
        label: 'Headline',
        placeholder: 'Short headline',
        type: 'textarea',
        ros: 3
      },
      {
        name: 'description',
        label: 'Full details',
        placeholder: 'Full details...',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'report_type',
        label: 'Report type',
        placeholder: 'Report type',
        type: 'textarea',
        ros: 3
      },
      {
        name: 'status_id',
        label: 'Status',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'impactstatus'
      },
      {
        name: 'country',
        label: 'Country',
        selected: null,
        type: 'select',
        options: countriesOptions
      },
      {
        name: 'issued',
        label: 'Date issued',
        type: 'date'
      },
      {
        name: 'effective',
        label: 'Date effective',
        type: 'date'
      },
      {
        name: 'first_reporting',
        label: 'First reporting date',
        type: 'date'
      },
      {
        name: 'mandatory',
        label: 'Mandatory reporting',
        type: 'checkbox'
      },
      {
        name: 'optional',
        label: 'Optional reporting',
        type: 'checkbox'
      },
      {
        name: 'comply_explain',
        label: 'Comply or explain',
        type: 'checkbox'
      },
      {
        name: 'assurance_id',
        label: 'Assurance required',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'assurance'
      },
      {
        name: 'reportingfrequency_id',
        label: 'Reporting frequency',
        selected: 1,
        type: 'selectOneFromDatabase',
        optionsModel: 'reportingfrequency'
      },
      {
        name: 'regulators',
        label: 'regulators',
        type: 'selectList',
        config: {
          labelLinkingField: 'regulator_id',
          labelModel: 'regulator',
          linkingModel: 'impactinitiativeregulator',
          name: 'Regulators',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'expert',
        label: 'Experts',
        type: 'selectList',
        config: {
          labelLinkingField: 'impactexpert_id',
          labelModel: 'impactexpert',
          linkingModel: 'impactexpertimpactinitiative',
          name: 'Experts',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'topic',
        label: 'Topics',
        type: 'selectList',
        config: {
          labelLinkingField: 'impacttopic_id',
          labelModel: 'impacttopic',
          linkingModel: 'impactinitiativeimpacttopic',
          name: 'Topics',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'reportingframework',
        label: 'Reporting framework',
        type: 'selectList',
        config: {
          labelLinkingField: 'reportingframework_id',
          labelModel: 'reportingframework',
          linkingModel: 'impactinitiativereportingframework',
          name: 'Reporting frameworks',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'aitag',
        label: 'AI Tags',
        type: 'selectList',
        config: {
          labelLinkingField: 'aitag_id',
          labelModel: 'aitag',
          linkingModel: 'aitagimpactinitiative',
          name: 'AI Tags',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'impactinitiativeregtopic',
          name: 'Regtopics',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'entity',
        label: 'Entities',
        type: 'selectList',
        config: {
          labelLinkingField: 'entity_id',
          labelModel: 'entity',
          linkingModel: 'entityimpactinitiative',
          name: 'Entities',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'regulation',
        label: 'Regulations',
        type: 'selectListSlim',
        config: {
          labelLinkingField: 'regulation_id',
          labelModel: 'regulation',
          linkingModel: 'impactinitiativeregulation',
          name: 'Regulations',
          nameProperty: 'name_short',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'event',
        label: 'Event',
        type: 'idLookup',
        config: {
          labelLinkingField: 'event_id',
          labelModel: 'event',
          linkingModel: 'eventimpactinitiative',
          name: 'Events',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'article',
        label: 'Insight',
        type: 'idLookup',
        config: {
          labelLinkingField: 'article_id',
          labelModel: 'article',
          linkingModel: 'articleimpactinitiative',
          name: 'Insights',
          nameProperty: 'title',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'publication',
        label: 'News',
        type: 'idLookup',
        config: {
          labelLinkingField: 'publication_id',
          labelModel: 'publication',
          linkingModel: 'impactinitiativepublication',
          name: 'News',
          parentLinkingField: 'impactinitiative_id',
          relation: 'impactinitiatives'
        }
      },
      {
        name: 'linkss',
        label: 'Links',
        type: 'links',
        config: {
          model: 'impactinitiativeslink',
          property: 'impactinitiative_id'
        }
      }
    ]
  },
  {
    id: 'impactstatus',
    model: 'impactstatus',
    name: 'Initiative status',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'impacttopic',
    model: 'impacttopic',
    name: 'Impact topic',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'aitag',
        label: 'AI Tags',
        type: 'selectList',
        config: {
          labelLinkingField: 'aitag_id',
          labelModel: 'aitag',
          linkingModel: 'aitagimpacttopic',
          name: 'AI Tags',
          parentLinkingField: 'impacttopic_id',
          relation: 'impacttopics'
        }
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'impacttopicregtopic',
          name: 'Regtopics',
          parentLinkingField: 'impacttopic_id',
          relation: 'impacttopics'
        }
      }
    ]
  },
  {
    id: 'person',
    model: 'person',
    name: 'Person',
    routeName: 'Person',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'type_id',
        label: 'Person type',
        type: 'selectOneFromDatabase',
        optionsModel: 'persontype'
      }
    ]
  },
  {
    id: 'persontype',
    model: 'persontype',
    name: 'Person type',
    routeName: 'Persontypes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'producttype',
    model: 'producttype',
    name: 'Producttype',
    routeName: 'Producttypes',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      }
    ]
  },
  {
    id: 'publication',
    model: 'publication',
    name: 'News items',
    fields: [
      {
        name: 'name',
        label: 'Headline',
        placeholder: 'Headline',
        type: 'textarea',
        rows: 2,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'body',
        label: 'Introduction',
        placeholder: 'Introcution',
        type: 'textarea',
        rows: 4,
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'link',
        label: 'Link',
        placeholder: 'wwww.example.com',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'publisher',
        label: 'Pubilsher',
        placeholder: 'FCA',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 1
            } else {
              return false
            }
          },
          text: 'Needs more than 1 characters'
        }
      },
      {
        name: 'source',
        label: 'Source',
        placeholder: 'FCA news',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 5
            } else {
              return false
            }
          },
          text: 'Needs more than 5 characters'
        }
      },
      {
        name: 'type',
        label: 'Type',
        placeholder: 'news',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 2
            } else {
              return false
            }
          },
          text: 'Needs more than 2 characters'
        }
      }
    ]
  },
  {
    id: 'regtopicgroup',
    model: 'regtopicgroup',
    name: 'Topic Group',
    routeName: 'Regtopicgroups',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Financial services',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'All regulatory topics related to financial services sector',
        type: 'input'
      },
      {
        name: 'del',
        label: 'Deleted',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'regtopic',
        label: 'Regtopics',
        type: 'selectList',
        config: {
          labelDelField: 'regtopics.del',
          labelLinkingField: 'regtopic_id',
          labelModel: 'regtopic',
          linkingModel: 'regtopicregtopicgroup',
          name: 'Regtopics',
          parentLinkingField: 'regtopicgroup_id',
          relation: 'regtopicgroups'
        }
      }
    ]
  },
  {
    id: 'regulation',
    model: 'regulation',
    name: 'Regulation',
    routeName: 'RegDecompRegulation',
    fields: [
      {
        name: 'name_short',
        label: 'Short name',
        placeholder: 'Short name',
        type: 'input'
      },
      {
        name: 'name_long',
        label: 'Long name',
        placeholder: 'Long name',
        type: 'input'
      },
      {
        name: 'code',
        label: 'Code',
        placeholder: 'code',
        type: 'input'
      },
      {
        name: 'url',
        label: 'URL',
        placeholder: 'www.example.com',
        type: 'input'
      },
      {
        name: 'uuid',
        label: 'UUID',
        placeholder: '9djsuwe83',
        type: 'input'
      },
      {
        name: 'country_code',
        label: 'Country',
        selected: null,
        type: 'select',
        options: countriesCodesOptions
      },
      {
        name: 'regulator_id',
        label: 'Regulator',
        type: 'selectOneFromDatabase',
        optionsModel: 'regulator'
      },
      {
        name: 'type_id',
        label: 'Type',
        type: 'selectOneFromDatabase',
        optionsModel: 'regulationtype'
      },
      {
        name: 'status_id',
        label: 'Status',
        type: 'selectOneFromDatabase',
        optionsModel: 'regulationstatus'
      },
      {
        name: 'date_in_force',
        label: 'Date in force',
        type: 'date'
      },
      {
        name: 'date_last_amended',
        label: 'Date last amended',
        type: 'date'
      },
      {
        name: 'relationships',
        label: 'Relationships',
        type: 'relationships',
        config: {
          graph: 'regulations'
        }
      },
      {
        name: 'pdf',
        label: 'PDF',
        selected: false,
        type: 'select',
        options: booleanOptions
      },
      {
        name: 'body',
        label: 'Body',
        placeholder: '<html></html>',
        type: 'textarea',
        rows: 30
      }
    ]
  },
  {
    id: 'relationship',
    model: 'relationship',
    name: 'Relationship',
    routeName: 'Relationships',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'supersedes',
        type: 'input'
      },
      {
        name: 'passive',
        label: 'Passive',
        placeholder: 'is superseded by',
        type: 'input'
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Explain relationship',
        type: 'input'
      },
      {
        name: 'del',
        label: 'Directional',
        selected: true,
        type: 'select',
        options: booleanOptions
      }
    ]
  },
  {
    id: 'reportingframework',
    model: 'reportingframework',
    name: 'Reporting framework',
    routeName: 'Reportingframework',
    fields: [
      {
        name: 'name',
        label: 'name',
        placeholder: 'TCFD',
        type: 'input'
      }
    ]
  },
  {
    id: 'reportingfrequency',
    model: 'reportingfrequency',
    name: 'Reporting frequency',
    routeName: 'Reportingfrequency',
    fields: [
      {
        name: 'name',
        label: 'reporting frequency',
        placeholder: 'annual / monthly',
        type: 'input'
      }
    ]
  },
  {
    id: 'riskcomponent',
    model: 'riskcomponent',
    name: 'Risk components',
    nameProperty: 'name',
    routeName: 'Riskcomponent',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input',
        validation: {
          method: function (value) {
            if (value) {
              return value.length > 3
            } else {
              return false
            }
          },
          text: 'Needs more than 3 characters'
        }
      },
      {
        name: 'description',
        label: 'Description',
        placeholder: 'Risk component description',
        type: 'editor',
        editor: {
          buttons: {
            bold: true,
            italics: true,
            h3: true,
            underline: true,
            ul: true,
            ol: true,
            quote: true,
            undo: true,
            redo: true
          }
        }
      },
      {
        name: 'del',
        label: 'Deleted',
        selected: false,
        type: 'select',
        options: deleteOptions
      }
    ]
  },
  {
    id: 'source',
    model: 'source',
    name: 'Source',
    routeName: 'Source',
    fields: [
      {
        name: 'source',
        label: 'Source',
        placeholder: 'Source',
        type: 'input'
      },
      {
        name: 'publisher',
        label: 'Publisher',
        placeholder: 'Publisher',
        type: 'input'
      },
      {
        name: 'type',
        label: 'Type',
        placeholder: 'Type',
        type: 'input'
      },
      {
        name: 'url',
        label: 'Url',
        placeholder: 'https://example.com',
        type: 'input'
      },
      {
        name: 'parser',
        label: 'Parser',
        placeholder: 'rss, duster, hoover, proxy',
        type: 'input'
      }
    ]
  },
  {
    id: 'ugroup',
    model: 'ugroup',
    name: 'User group',
    fields: [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        type: 'input'
      },
      {
        name: 'faqproject',
        label: 'Faqproject',
        type: 'selectList',
        config: {
          labelLinkingField: 'faqproject_id',
          labelModel: 'faqproject',
          linkingModel: 'faqprojectugroup',
          name: 'FAQ Project',
          parentLinkingField: 'ugroup_id',
          relation: 'ugroups'
        }
      },
      {
        name: 'user',
        label: 'User',
        type: 'selectList',
        config: {
          labelLinkingField: 'user_id',
          labelModel: 'user',
          linkingModel: 'ugroupuser',
          name: 'Users',
          nameProperty: 'username',
          parentLinkingField: 'ugroup_id',
          relation: 'ugroups'
        }
      }
    ]
  }
]
